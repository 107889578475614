<template>
  <div id="invite-friend-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="if-page-header">
      <el-page-header :title="$t('common.i_back')" :content="$t('inviteFriend.i_inviteFriend')" @back="$router.push('/index')" style="float: left;"></el-page-header>
      <div style="float: right;background: white;padding: 2px 6px 3px 6px;border-radius: 20px;">
        <img src="@/assets/common/moneyIcon.png" style="width: 14px;vertical-align: middle;" />
        <span style="vertical-align: middle;text-decoration: none;color: black;font-size: 14px;color: #17BB17;padding-right: 2px;"> {{$store.state.goldNum}}</span>
      </div>
    </div>

    <div style="margin-top: 5%;">
      <div>
        <img src="@/assets/images/inviteFriend001.png" style="width: 120px;vertical-align: bottom;" />
      </div>
      <div style="position: relative;width: 270px;margin: auto;">
        <img src="@/assets/images/inviteFriend002.png" style="width: 270px;vertical-align: top;" />
        <span style="position: absolute;box-sizing: border-box;left: 0px;top: 8px;color: white;width: 270px;">{{$t('inviteFriend.i_inviteT')}}</span>
      </div>
    </div>

    <div>
      <div style="margin-top: 5%;font-size: 18px;">
        {{inviteReward}}<img style="width: 15px;margin-left: 1px;" src="@/assets/common/moneyIcon.png" />
        &nbsp;+{{commission}}%&nbsp;
        <span style="font-weight: bold;font-size: 15px;">{{$t('inviteFriend.i_commissionT')}}</span>
      </div>
      <div style="font-weight: bold;font-size: 16px;">
        {{$t('inviteFriend.i_total')}} {{inviteReward+totalReward}}<img style="width: 15px;margin-left: 1px;" src="@/assets/common/moneyIcon.png" />
      </div>
    </div>

    <div style="overflow: hidden;font-size: 21px;background: white;padding: 3%;border-radius: 8px;margin-left: 10%;margin-right: 10%;margin-top: 5%;margin-bottom: 3%;">
      <span style="float: left;">{{$t('inviteFriend.i_friends')}}&nbsp;{{inviteNum.inviteNum}}</span>
      <span style="float: right;margin-top: 1%;"><img src="@/assets/common/moneyIcon.png" style="width: 16px;margin-left: 1px;" />{{inviteNum.totalGoldNum}}</span>
    </div>

    <div style="background: rgba(0, 183, 0, 1);border-radius: 8px;padding-top: 8%;padding-bottom: 4%;margin-top: 10%;">
      <div>
        <span style="position: relative;">
          <img width="110" src="@/assets/images/inviteFriend003.png" style="margin-right: 5px;margin-left: 15px;" />
          <span style="position: absolute;box-sizing: border-box;left: 18px;font-size: 13px;width: 90px;bottom: 50px;">{{$t('inviteFriend.i_text1')}}</span>
          <!-- <span style="position: absolute;box-sizing: border-box;left: 18px;font-size: 13px;width: 90px;bottom: 54px;">Friends register and enter invitation code</span> -->
        </span>

        <span style="position: relative;">
          <img width="110" src="@/assets/images/inviteFriend004.png" style="margin-right: 5px;" />
          <span style="position: absolute;box-sizing: border-box;left: 4px;font-size: 13px;width: 90px;bottom: 50px;">{{$t('inviteFriend.i_text2')}}</span>
          <!-- <span style="position: absolute;box-sizing: border-box;left: 4px;font-size: 13px;width: 90px;bottom: 54px;">Activate Premium Features</span> -->
        </span>

        <span style="position: relative;">
          <img width="110" src="@/assets/images/inviteFriend005.png" style="" />
          <span style="position: absolute;box-sizing: border-box;left: 4px;font-size: 13px;width: 90px;bottom: 50px;">{{$t('inviteFriend.i_text3')}}</span>
          <!-- <span style="position: absolute;box-sizing: border-box;left: 4px;font-size: 13px;width: 90px;bottom: 54px;">Become your friend</span> -->
        </span>
      </div>

      <div v-if="isActivation == true" class="invite-code" style="color: white;margin-top: 5%;">
        {{$t('inviteFriend.i_code')}}：
        <div class="invite-code-text">
          <span @click="copyVal($event.target.innerText)" style="color: white;">{{inviteCode}}</span>
          <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
            <img src="@/assets/common/copyIconW.png" style="width: 10px;margin-bottom: 8px;" />
          </span>
        </div>
      </div>

      <div style="width: 300px;margin: auto;position: relative;margin-top: 5%;">
        <el-button style="" type="primary" @click="drawer = true">{{$t('inviteFriend.i_inviteNow')}}</el-button>
        <span @click="$router.push('/inviteLog')">
          <svg style="position: absolute;top: 2px;margin-left: 20px;" t="1655965911443" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2350" width="32" height="32">
            <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2351" fill="#ffffff"></path>
            <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2352" fill="#ffffff"></path>
          </svg>
        </span>
      </div>

      <div style="margin-top: 8%;margin-bottom: 10%;">
        <router-link style="text-decoration: none;" to="">
          <span @click="centerDialogVisible = true">
            &nbsp;
            <svg t="1655967125170" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3561" width="13" height="13">
              <path d="M336.4864 711.8848a26.0096 26.0096 0 0 1-26.0096-27.0336l4.9152-125.952a25.8048 25.8048 0 0 1 7.5776-17.408L809.1648 55.7056a122.88 122.88 0 0 1 81.92-36.4544 109.1584 109.1584 0 0 1 81.92 31.1296 107.1104 107.1104 0 0 1 31.1296 81.92 122.88 122.88 0 0 1-36.4544 81.92l-486.8096 485.376a25.8048 25.8048 0 0 1-17.408 7.5776l-125.952 4.9152z m125.952-30.72z m-95.4368-109.9776l-3.4816 87.8592L450.56 655.36 930.2016 176.7424a71.2704 71.2704 0 0 0 20.48-47.3088 54.6816 54.6816 0 0 0-58.5728-58.5728 71.2704 71.2704 0 0 0-47.3088 20.48z" p-id="3562" fill="#2c2c2c"></path>
              <path d="M801.5872 1004.9536H171.008A150.528 150.528 0 0 1 20.48 854.4256V223.8464a150.528 150.528 0 0 1 150.528-150.528h413.2864a26.0096 26.0096 0 0 1 0 51.8144H171.008a98.7136 98.7136 0 0 0-98.5088 98.5088v630.784a98.7136 98.7136 0 0 0 98.5088 98.5088h630.5792a98.7136 98.7136 0 0 0 98.5088-98.5088V440.9344a26.0096 26.0096 0 1 1 51.8144 0v413.4912a150.528 150.528 0 0 1-150.3232 150.528z" p-id="3563" fill="#2c2c2c"></path>
            </svg>
            {{$t('inviteFriend.i_enterCode')}}
            &nbsp;
          </span>
        </router-link>
      </div>
    </div>

    <el-dialog :title="$t('inviteFriend.i_enterCode')" :visible.sync="centerDialogVisible" width="80%" center>
      <el-form style="text-align: center;">
        <el-form-item :label="$t('inviteFriend.i_friendCode')" prop="inviteCode">
          <span style="float:left"><span style="color:rgba(0, 183, 0, 1);font-size: 20px;">+{{beInviteReward}}</span><img style="width: 15px;margin-left: 1px;" src="@/assets/common/moneyIcon.png" /></span>
          <el-input type="text" v-model="inviteForm.inviteCode" :placeholder="$t('inviteFriend.i_code1')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="inviterUidInviteCode==null" type="primary" @click="getItNow()">{{$t('inviteFriend.i_claimNow')}}</el-button>
          <el-button v-else type="info">{{$t('inviteFriend.i_filledIn')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="drawer">
      <div>
        <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow: hidden;">
          <img src="@/assets/images/gameIcon.png" style="border-radius: 10px;float: left;height: 50px;" />
          <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;">Magic&nbsp;Cash</span>
        </div>
        <div style="margin: 5%;background-color: rgba(223, 223, 227, 1);border-radius: 6px;padding-top: 3%;padding-bottom: 3%;">
          <span style="">
            Share to you a platform【Magic Cash】that you can play games and earn money...
            <a :href="invitationLink">{{this.invitationLink}}</a>
          </span>
        </div>
        <div>
          <el-button type="primary" @click="copyVal($event.currentTarget.parentElement.previousElementSibling.firstElementChild.innerText)" style="width: 38%;">
            <img width="14" src="@/assets/common/copyIconW.png" />
            &nbsp;
            <span style="font-size: 20px;">Copy</span>
          </el-button>
        </div>
      </div>
    </el-drawer>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="bindDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="background: rgba(218, 248, 201, 1);padding: 6%;text-align: center;margin-bottom: 6%;">
        <div>
          <span style="font-size: 16px;color: #000;">{{$t('convert.i_activateMsg')}}</span>
        </div>
      </div>
      <div style="text-align: center;">
        <el-button type="primary" @click="$router.push('/moneySteps')" style="width: 60%;">{{$t('convert.i_ok')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      centerDialogVisible: false,
      bindDialog: false,
      isActivation: false,
      inviteForm: {
        inviteCode: ""
      },
      drawer: false,
      inviteCode: "",
      invitationLink: "",
      inviterUidInviteCode: null,
      inviteNum: {
        inviteNum: 0,
        totalGoldNum: 0
      },
      inviteReward: 0,
      beInviteReward: 0,
      commission: 0,
      totalReward: 0
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getItNow() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/web/checkInvite",
        method: "get",
        params: {
          inviteCode: this.inviteForm.inviteCode
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "success",
            center: true
          });
          this.centerDialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    }
  },
  created() {
    this.isLoading = true;
    this.$axios({
      url: "/webAndGame/web/inviteFriend",
      method: "get"
    }).then((result) => {
      this.isLoading = false;
      if (result.data.code == 1) {
        this.$store.state.goldNum = result.data.data.goldNum;
        this.inviteCode = result.data.data.inviteCode;
        this.invitationLink = result.data.data.invitationLink;
        this.inviterUidInviteCode = result.data.data.inviterUidInviteCode;
        this.inviteForm.inviteCode = result.data.data.inviterUidInviteCode;
        this.inviteNum = result.data.data.inviteNum;
        this.inviteReward = result.data.data.inviteReward;
        this.beInviteReward = result.data.data.beInviteReward;
        this.commission = result.data.data.commission;
        this.totalReward = result.data.data.totalReward;
        this.isActivation = result.data.data.isActivation;
        if (this.isActivation == false) {
          this.bindDialog = true;
        }
      } else {
        this.$message({
          showClose: true,
          message: result.data.message,
          type: "error",
          center: true
        });
      }
    });
  }
};
</script>

<style>
#invite-friend-layout {
  width: 100%;
  height: 100%;
  background: rgba(218, 248, 201, 1);
}
#invite-friend-layout .if-page-header {
  padding: 4%;
  overflow: hidden;
}
#invite-friend-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}
</style>
