<template>
  <div id="convert-log-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="cl-page-header">
      <el-page-header :title="$t('common.i_back')" :content="$t('convert.i_convertLog')" @back="$router.push('/convert')" style="float: left;"></el-page-header>
      <div style="float: right;background: white;padding: 2px 6px 3px 6px;border-radius: 20px;">
        <img src="@/assets/common/moneyIcon.png" style="width: 14px;vertical-align: middle;" />
        <span style="vertical-align: middle;text-decoration: none;color: black;font-size: 14px;color: #17BB17;padding-right: 2px;"> {{$store.state.goldNum}}</span>
      </div>
    </div>
    <div style="font-size: 12px;margin-top: 5%;text-align: left;margin-left: 5%;">*Please wait 7 working days patiently</div>

    <div style="margin-left: 5%;margin-right: 5%;margin-top: 5%;overflow-y: scroll;height: 83vh;">
      <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div v-for="log in convertLog" :key="log.id" style="background-color: #C6E2B6;margin-bottom: 3%;border-radius: 8px;padding: 4%;">
          <div style="overflow: hidden;">
            <div style="float: left;color: #00B400;font-size: 22px;">
              {{log.price}}
            </div>
            <div style="float: right;">
              <span v-if="log.status == 1" style="color: #00B400;">
                {{$t('convert.i_reviewSucceeded')}}
              </span>
              <span v-else-if="log.status == 0" style="color: #807FDF;">
                {{$t('convert.i_underReview')}}
              </span>
              <span v-else-if="log.status == 2" style="color: red;">
                {{$t('convert.i_auditFailure')}}
              </span>
              <span v-else-if="log.status == 3" style="color: red;">
                {{$t('convert.i_invalid')}}
              </span>
            </div>
          </div>
          <div style="overflow: hidden;font-size: 13px;">
            <div style="float: left;margin-top: 2%;">
              {{log.cardName}}
            </div>
            <div style="float: right;margin-top: 2%;">
              {{log.time}}
            </div>
          </div>
          <div v-if="log.status == 2||log.status == 3" style="color: red;text-align: left;font-size: 12px;margin-top: 2%;">{{$t('convert.i_remark')}}：{{log.remark}}</div>
        </div>
      </div>

      <div v-if="convertLog==''">
        <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" />
        <div style="color: rgba(168, 198, 153, 1);margin-top: 5%;">{{$t('inviteFriend.i_noLog')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      convertLog: [],
      busy: false,
      pageNum: 0,
      isLast: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webAndGame/web/log",
          method: "get",
          params: {
            pageNum: this.pageNum,
            recordType: -1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            result.data.data.log.forEach((element) => {
              this.convertLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });

        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
    this.$axios({
      url: "/webAndGame/web/log",
      method: "get",
      params: {
        pageNum: 1,
        recordType: -1
      }
    }).then((result) => {
      this.isLoading = false;
      if (result.data.code == 1) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else if (result.data.code == 2) {
        this.$store.state.goldNum = result.data.data.goldNum;
      } else {
        this.$message({
          showClose: true,
          message: result.data.message,
          type: "error",
          center: true
        });
      }
    });
  }
};
</script>

<style>
#convert-log-layout {
  width: 100%;
  height: 100%;
}
.cl-page-header {
  padding: 4% 4% 0 4%;
  overflow: hidden;
}
</style>
