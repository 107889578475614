const zh = {
    common: {
        i_loading: '拼命加载中。。。',
        i_back: '返回',
        i_timedOut: '请求超时，请检查网络',
        i_startEarning: '开始赚钱',
        i_copy: '复制成功',
        i_cancel: '取消',
    },
    vCode: {
        i_sliderText: '拖动滑块完成拼图',
        i_failText: '验证失败，请重试',
        i_successText: '验证通过'
    },
    register: {
        i_register: '注册',
        i_enterYourEmail: '输入你的Email',
        i_getVerifyCode: '获取验证码',
        i_enterVerifyCode: '输入验证码',
        i_enterPass: '输入密码',
        i_checkYourPass: '确认你的密码',
        i_inviteCodeT: '邀请码（可选）',
        i_enterInviteCode: '输入邀请码',
        i_autoLogin: '注册完成后自动登录',
        i_goRegister: '立即注册',
        i_goLogin: '前往登录',
        i_registeredSuccess: '恭喜您注册成功',
        i_startEarning: '前往登录，开始赚钱吧！',
        i_enter6VerifyCode: '请输入6位验证码',
        i_specialSymbols: '特殊符号只能是~!@#$%^&*',
        i_pass6: '密码不能少于6位',
        i_pass30: '密码不能大于30位',
        i_checkTwoPass: '两次输入密码不一致',
        i_emailAddressEmpty: '邮箱地址不能为空',
        i_checkEmail: '请输入正确的邮箱地址',
        i_verifyCodeEmpty: '验证码不能为空',
        i_passEmpty: '密码不能为空',
        i_checkPassEmpty: '确认密码不能为空',
        i_inProgress: '正在获取中',
        i_reRequest: '重新获取',
        i_verifyCodeMsg: '验证码已发送至您的邮箱，请注意查收！',
        i_pleaseUseNewPlatform: "请使用我们的新平台"
    },
    login: {
        i_playGames: '玩游戏赚现金',
        i_login: '登录',
        i_enterYourEmail: '输入你的邮箱',
        i_enterYourPass: '输入你的密码',
        i_forgotPass: '忘记密码?',
        i_goLogin: '立即登录',
        i_goRegister: '注册账号'
    },
    forgotPassword: {
        i_forgotPass: '忘记密码',
        i_verify: '验证',
        i_modify: '修改',
        i_finish: '完成',
        i_next: '下一步',
        i_enterYourNewPass: '输入你的新密码',
        i_updatePass: '更改密码',
        i_successUpdatePass: '修改密码成功',
        i_goLoginMsg: '前往登录，开始赚钱吧！'
    },
    index: {
        i_coinsLog: '金币记录',
        i_totalCoins: '你已积累的财富',
        i_convert: '兑换',
        i_convertMsg: '金币换现金',
        i_inviteFriends: '邀请好友',
        i_inviteFriendsMsg: '领取更多金币',
        i_inviteCode: '邀请码',
        i_accountC: "账户中心"
    },
    moenySteps: {
        i_earningSteps: "赚钱步骤",
        i_moenyStepsMsg: "游戏内的金币数量就是你的账号的金币数量",
        i_download: "下载",
        i_activate: "激活高级功能",
        i_status: "状态",
        i_activateY: "已激活",
        i_activateN: "未激活",
        i_goActivate: "立即激活",
        i_actTips: "请先下载App",
        i_getCoins: "获取金币",
        i_howToGetCoins: "获取金币的途径：",
        i_chest: "宝箱",
        i_luckyDraw: "大转盘",
        i_level: "关卡",
        i_coinsForCash: "金币换成现金",
        i_img: "zh"
    },
    convert: {
        i_convert: "兑换",
        i_convertLog: "兑换记录",
        i_convertT: "将你的金币换成现金",
        i_choose: "请选择兑换方式",
        i_input: "输入",
        i_account: "收款账号",
        i_editAccount: "修改账号",
        i_checkN: "输错号码概不负责",
        i_receivingName: "收款姓名",
        i_enterReceivingName: "输入收款姓名",
        i_select: "选择兑换货币",
        i_currency: "兑换货币",
        i_pleaseSelect: "请选择",
        i_bindAccount: "绑定收款账号",
        i_enterReceivingAccount: "输入收款账号",
        i_redeemNow: "立即兑换",
        i_bindN: "绑定后不可更改",
        i_bindNow: "立即绑定",
        i_following: "以下",
        i_bindT: "账户即将绑定为你的收款账户，绑定后不可更改，确定绑定吗？",
        i_bind: "确定绑定",
        i_changeNow: "立即更改",
        i_changeT: "申请修改需要等待管理员审核，审核期间将不能进行兑换，确认更改吗？",
        i_newReceivingAccount: "新的收款账号",
        i_modifyReceivingAccount: "修改收款账号",
        i_newReceivingName: "新的收款姓名",
        i_confirm: "确定更改",
        i_ok: "好的",
        i_convertMsg: "兑换申请已经提交，正在审核中",
        i_info_ing: "信息审核中",
        i_infoN: "信息审核失败",
        i_tips: "提示",
        i_activateMsg: "请先激活高级功能",
        i_accountN: "账号不能为空",
        i_nameN: "姓名不能为空",
        i_reviewSucceeded: "审核成功",
        i_underReview: "审核中",
        i_auditFailure: "审核失败",
        i_invalid: "无效",
        i_remark: "备注"
    },
    inviteFriend: {
        i_inviteFriend: "邀请好友",
        i_inviteT: "每邀请一个好友奖励",
        i_commissionT: "好友的提成",
        i_total: "总共",
        i_friends: "好友",
        i_text1: "好友注册账号并输入邀请码",
        i_text2: "激活高级功能",
        i_text3: "成为你的好友",
        i_code: "邀请码",
        i_inviteNow: "立即邀请",
        i_enterCode: "填写邀请码",
        i_friendCode: "填写好友邀请码",
        i_code1: "只能填写1位好友邀请码",
        i_claimNow: "立即领取",
        i_inviteLog: "邀请记录",
        i_notActivated: "未升级宝箱",
        i_filledIn: "已填写",
        i_noLog: "暂无内容"
    },
    invite: {
        inviteImg: "zh"
    },
    language: {
        zh: '中文',
        en: 'English',
    },
}
export default zh;
